import { ProjectProps } from "../../types/interfaces";
import { ProjectView } from "./styles";

import github from "../../assets/github.svg";

export const Project: React.FC<ProjectProps> = ({
  name,
  description,
  repositories,
}) => {
  return (
    <ProjectView>
      <h4>{name}</h4>

      <h5>{description}</h5>

      <div>
        {
          repositories.map((repository) => (
            <a key={repository.url} href={repository.url} target="_blank" rel="noreferrer">
              <img src={github} alt="" />

              {repository.label}
            </a>
          ))
        }
      </div>
    </ProjectView>
  );
};
