import styled from "styled-components";

export const Section = styled.section<{
  $background?: string;
  $fullHeight?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ $fullHeight = false }) => $fullHeight ? '100%' : 'auto'};
  background-color: ${({ $background = ""}) => $background};
`;

export const Content = styled.div<{
  $align?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $align = 'auto'}) => $align};
  width: 100%;
  max-width: 1024px;
  padding: 32px 72px;

  @media (max-width: 1024px) {
    padding: 24px 32px;
  }
`;
