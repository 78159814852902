import styled from "styled-components";

export const SkillView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  transition: all .2s;

  & > img {
    width: 48px;
    height: 48px;
  }

  & > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-left: 16px;
    padding: 4px 0;
  }

  & > div > span {
    color: ${({ theme }) => theme.colors.primary};
  }

  & > div > div {
    display: flex;
    flex-direction: row;
  }

  &:hover {
    filter: ${({ theme }) => `drop-shadow(${theme.shadow.hover})`};
  }
`;
