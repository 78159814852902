import styled from "styled-components";

import profile from "../../assets/profile.jpg";
import { Size } from "../../types/types";
import { Sizes } from "../../types/enums";

export const Profile = styled.div<{
  size: Size;
}>`
  background-image: url(${profile});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: border-box;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadow.default};

  ${({ size }) => getSize(size)}
`;

const getSize = (size: Size) => {
  const sizeObject: {
    [key in Sizes]: string;
  } = {
    small: `
      min-width: 80px;
      min-height: 80px;
    `,
    medium: `
      min-width: 192px;
      min-height: 192px;
    `,
    large: `
      min-width: 192px;
      min-height: 192px;
    `,
  };

  return sizeObject[size];
};
