import styled from "styled-components";

export const ProjectView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 128px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  transition: all .2s;

  & > h4 {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.primary};
  }

  & > h5 {
    flex: 1;
    margin-top: 8px;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
  }

  & > div > a {
    display: flex;
    align-items: center;
    align-self: flex-end;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    transition: all .2s;
    text-decoration: none;
    margin-right: 16px;
  }

  & > div > a:last-of-type {
    margin-right: 0;
  }

  & > div > a > img {
    margin-right: 8px;
  }

  & > div > a:hover {
    filter: ${({ theme }) => `drop-shadow(${theme.shadow.hover})`};
  }

  & > div > a:active {
    scale: .98;
  }
`;
