import styled from "styled-components";

export const LinkView = styled.a`
  width: 64px;
  text-decoration: none;

  & > button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  & > button > span {
    margin-top: 16px;
    color: ${({ theme }) => theme.colors.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  }

  &:hover {
    filter: ${({ theme }) => `drop-shadow(${theme.shadow.hover})`};
  }
`;
