import styled from "styled-components";

import logo from "../../assets/logo.svg";
import logoHorizontal from "../../assets/logo-horizontal.svg";
import { Size } from "../../types/types";
import { Sizes } from "../../types/enums";

export const Logo = styled.img.attrs({
  src: logo,
})<{
  size: Size;
  $shadow?: boolean;
}>`
  filter: ${({ theme, $shadow = true }) => $shadow ? `drop-shadow(${theme.shadow.default})` : 'none'};

  ${({ size }) => getSize(size)}
`;

export const LogoHorizontal = styled.img.attrs({
  src: logoHorizontal,
})<{
  size: Size;
  $shadow?: boolean;
}>`
  filter: ${({ theme, $shadow = true }) => $shadow ? `drop-shadow(${theme.shadow.default})` : 'none'};

  ${({ size }) => getSize(size)}
`;

const getSize = (size: Size) => {
  const sizeObject: {
    [key in Sizes]: string;
  } = {
    small: `
      height: 24px;
    `,
    medium: `
      height: 72px;
    `,
    large: `
      height: 192px;
    `,
  };

  return sizeObject[size];
};
