import { SectionTitleProps } from "../../types/interfaces";
import { SectionTitleView } from "./styles";

export const SectionTitle: React.FC<SectionTitleProps> = ({
  label,
}) => {
  return (
    <SectionTitleView>
      <h3>{label}</h3>

      <div />
    </SectionTitleView>
  );
};
