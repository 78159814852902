import styled from "styled-components";

export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 200;
  font-size: 48px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.primary};
  text-shadow: 0px 0px 8px #0F0F0F;
`;

export const SubTitle = styled.h2`
  margin: 0;
  font-style: italic;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary};
  text-shadow: 0px 0px 8px #0F0F0F;
`;

export const ScrollButton = styled.a`
  position: absolute;
  bottom: 32px;
  transition: all .2s;

  &:hover {
    filter: ${({ theme }) => `drop-shadow(${theme.shadow.hover})`};
  }

  &:active {
    scale: .98;
  }
`;

export const About = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > h4 {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.secondary};
    margin: 0;
    width: 576px;
    text-align: justify;
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    & > h4 {
      width: 100%;
      margin-top: 32px;
    }
  }
`;

export const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 152px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  transition: all .2s;
  text-decoration: none;
  margin-top: 16px;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors.secondary};
`;
