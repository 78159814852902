import { CaretDoubleRight } from "@phosphor-icons/react";
import { Button } from "../Button";
import { Label, Link } from "./styles";
import theme from "../../config/theme";
import { LinkProps } from "../../types/interfaces";
import { MarginBox } from "../MarginBox";

export const LinkButton: React.FC<LinkProps> = ({
  label,
  icon,
  onClick = () => { },
  margin = '',
  ...rest
}) => {
  return (
    <MarginBox $margin={margin}>
      <Link onClick={onClick} {...rest}>
        <Button>
          {icon}

          <Label>{label}</Label>

          <CaretDoubleRight color={theme.colors.primary} size={24} />
        </Button>
      </Link>
    </MarginBox>
  );
};
