const theme = {
  colors: {
    primary: '#D9D9D9',
    secondary: '#ABABAB',
    background: '#272727'
  },

  shadow: {
    default: '0px 0px 8px #0F0F0F',
    hover: '0px 0px 8px #D9D9D95E',
  },

  borderRadius: {
    small: '6px',
    medium: '12px',
    large: '16px',
  },
};

export default theme;
