import styled from "styled-components";

export const MarginBox = styled.div<{
  $margin: string;
  $width?: string;
  $height?: string;
}>`
  width: ${({ $width = 'auto'}) => $width};
  height: ${({ $height = 'auto'}) => $height};
  margin: ${({ $margin }) => $margin};
`;
