import styled from "styled-components";

export const Grid = styled.div<{
  $columns: number;
  $mobileColumns?: number;
  $width?: string;
  $gap?: string;
}>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  gap: ${({ $gap = 'auto'}) => $gap};
  width: ${({ $width = 'auto'}) => $width};

  @media (max-width: 1024px) {
    width: 100%;
    grid-template-columns: repeat(${({ $mobileColumns = 1 }) => $mobileColumns}, 1fr);
  }
`;
