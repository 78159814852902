import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 320px;
  padding: 32px 24px 16px 24px;
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) => theme.shadow.default};
  border-radius: 16px;
`;

export const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.primary};
  text-shadow: 0px 0px 8px #0F0F0F;
`;

export const SubTitle = styled.h2`
  margin: 0;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondary};
  text-shadow: 0px 0px 8px #0F0F0F;
`;
