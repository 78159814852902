import { SkillView } from "./styles";

import { Star } from "@phosphor-icons/react";
import theme from "../../config/theme";
import { SkillProps } from "../../types/interfaces";

export const Skill: React.FC<SkillProps> = ({
  name,
  rating,
  img,
}) => {
  return (
    <SkillView>
      {img}

      <div>
        <span>{name}</span>

        <div>
          {
            [ 1, 2, 3, 4, 5 ].map((value) => {
              return rating >= value ? (
                <Star key={value} weight="fill" color={theme.colors.primary} />
              ) : (
                <Star key={value} color={theme.colors.primary} />
              );
            })
          }
        </div>
      </div>
    </SkillView>
  );
};
