import styled from "styled-components";

import background from "../../assets/background.png";

export const LayoutView = styled.main`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Background = styled.div`
  position: fixed;
  inset: 0;
  z-index: -1;
  opacity: 0.009;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-clip: border-box;
`;
