import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  transition: all .2s;

  &:hover {
    filter: ${({ theme }) => `drop-shadow(${theme.shadow.hover})`};
  }

  &:active {
    scale: .98;
  }
`;
