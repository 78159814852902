export enum Sizes {
  sm = "small",
  md = "medium",
  lg = "large",
};

export enum Skills {
  html = "html",
  css = "css",
  js = "js",
  node = "node",
  react = "react",
  rn = "rn",
  ts = "ts",
  flutter = "flutter",
  java = "java",
};
