import styled from "styled-components";

export const SectionTitleView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & > h3 {
    font-style: italic;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0;
    margin-right: 16px;
  }

  & > div {
    flex: 1;
    height: 1px;
    content: "";
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;
