import { Profile } from "../../components/Profile";
import { Section } from "../../components/Section";
import { Sizes } from "../../types/enums";
import { Logo, LogoHorizontal } from "../../components/Logo";
import { Card, SubTitle, Title } from "./styles";
import { MarginBox } from "../../components/MarginBox";
import { GithubLogo, LinkedinLogo, WhatsappLogo } from "@phosphor-icons/react";
import { LinkButton } from "../../components/LinkButton";
import theme from "../../config/theme";
import { useNavigate } from "react-router-dom";
import { LinkProps } from "../../types/interfaces";

export const Home = () => {
  const navigate = useNavigate();

  const links: Array<LinkProps> = [
    {
      label: "Portfólio",
      icon: <Logo $shadow={false} size={Sizes.sm} />,
      onClick: () => navigate('/portfolio'),
      margin: "",
    },
    {
      label: "LinkedIn",
      icon: <LinkedinLogo color={theme.colors.primary} size={24} />,
      href: "https://www.linkedin.com/in/fernando-ridam/",
      margin: "24px 0 0 0",
      target: "_blank",
    },
    {
      label: "GitHub",
      icon: <GithubLogo color={theme.colors.primary} size={24} />,
      href: "https://github.com/FernandoRidam",
      margin: "24px 0 0 0",
      target: "_blank",
    },
    {
      label: "Whatsapp",
      icon: <WhatsappLogo color={theme.colors.primary} size={24} />,
      href: "https://wa.me/5562996757320",
      margin: "24px 0 0 0",
      target: "_blank",
    },
  ];

  return (
    <Section $fullHeight>
      <Card>
        <Profile size={Sizes.sm} />

        <MarginBox $margin="16px 0 4px 0">
          <Title>Fernando Ridam</Title>
        </MarginBox>

        <SubTitle>Desenvolvedor Fullstack</SubTitle>

        <MarginBox $margin="40px 0 48px 0" $width="100%">
          { links.map((link) => <LinkButton key={link.label} {...link} />)}
        </MarginBox>

        <LogoHorizontal size={Sizes.sm} />
      </Card>
    </Section>
  );
};
