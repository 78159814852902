import {
  createBrowserRouter, redirect,
} from "react-router-dom";
import { Home } from "../pages/Home";
import { Layout } from "../components/Layout";
import { Portfolio } from "../pages/Portfolio";

const appRouter = createBrowserRouter([
  {
    path: "/",
    Component: Layout,
    children: [
      {
        path: "",
        Component: Home,
      },

      {
        path: "/portfolio",
        Component: Portfolio,
      },

      {
        path: "*",
        loader: () => {
          return redirect(`/`);
        }
      },
    ]
  },
]);

export default appRouter;
