import { differenceInCalendarYears, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CaretDoubleDown, Download, EnvelopeSimple, GithubLogo, LinkedinLogo, WhatsappLogo } from "@phosphor-icons/react";

import { MarginBox } from "../../components/MarginBox";
import { Logo, LogoHorizontal } from "../../components/Logo";
import { Content, Section } from "../../components/Section";
import { SectionTitle } from "../../components/SectionTitle";
import { Profile } from "../../components/Profile";
import { Grid } from "../../components/Grid";
import { Skill } from "../../components/Skill";
import { Project } from "../../components/Project";
import { Link } from "../../components/Link";

import { Sizes } from "../../types/enums";
import { LinkProps, ProjectProps, SkillProps } from "../../types/interfaces";
import theme from "../../config/theme";

import { About, DownloadButton, Footer, ScrollButton, SubTitle, Title } from "./styles";

import html from "../../assets/html.svg";
import css from "../../assets/css.svg";
import js from "../../assets/js.svg";
import node from "../../assets/node.svg";
import react from "../../assets/react.svg";
import rn from "../../assets/rn.svg";
import ts from "../../assets/ts.svg";
import flutter from "../../assets/flutter.svg";
import java from "../../assets/java.svg";

const age = differenceInCalendarYears(new Date(), new Date(1998, 2));
const timeExperience = formatDistanceToNow(new Date(2018, 7), {
  locale: ptBR,
  addSuffix: true,
});

const skills: Array<Omit<SkillProps, 'showDescription' | 'onClose'>> = [
  {
    name: 'HTML',
    img: <img src={html} alt="HTML" />,
    rating: 5,
  },
  {
    name: 'CSS',
    img: <img src={css} alt="CSS" />,
    rating: 5,
  },
  {
    name: 'JavaScript',
    img: <img src={js} alt="JavaScript" />,
    rating: 5,
  },
  {
    name: 'NodeJS',
    img: <img src={node} alt="NodeJS" />,
    rating: 5,
  },
  {
    name: 'ReactJS',
    img: <img src={react} alt="ReactJS" />,
    rating: 5,
  },
  {
    name: 'React Native',
    img: <img src={rn} alt="React Native" />,
    rating: 4,
  },
  {
    name: 'TypeScript',
    img: <img src={ts} alt="TypeScript" />,
    rating: 4,
  },
  {
    name: 'Flutter',
    img: <img src={flutter} alt="Flutter" />,
    rating: 3,
  },
  {
    name: 'Java',
    img: <img src={java} alt="Java" />,
    rating: 2,
  },
];

const projects: Array<ProjectProps> = [
  {
    name: 'Cine App',
    description: "Aplicativo de consulta e dicas de filmes e séries. Listagem, busca e detalhar filmes e séries em Inglês ou Português nas configurações pode-se escolher a linguagem do aplicativo.",
    repositories: [
      {
        label: "Repositório",
        url: "https://github.com/FernandoRidam/cine-app-rn",
      }
    ],
  },
  {
    name: 'Pokedex React Native',
    description: "Aplicação em React Native para listar e detalhar Pokémon, usando a API PokeApi.",
    repositories: [
      {
        label: "Repositório",
        url: "https://github.com/FernandoRidam/pokemon-rn",
      }
    ],
  },
  {
    name: 'DigPoint',
    description: "Aplicação de controle de jornada simples.",
    repositories: [
      {
        label: "Backend",
        url: "https://github.com/FernandoRidam/digpoint-api",
      },
      {
        label: "Frontend",
        url: "https://github.com/FernandoRidam/digpoint-app",
      }
    ],
  },
  {
    name: 'MySql Query Builder',
    description: "Lib que auxilia na construção de consultas SQL com Typescript que auxilia com sugestões e sintaxe coerentes para os ambientes JS e MySql. Utilização sugerida com a lib MySql2.",
    repositories: [
      {
        label: "Repositório",
        url: "https://github.com/FernandoRidam/mysql-query-builder-ts",
      },
    ],
  },
];

const links: Array<LinkProps> = [
  {
    label: "LinkedIn",
    icon: <LinkedinLogo color={theme.colors.primary} size={24} />,
    href: "https://www.linkedin.com/in/fernando-ridam/",
    target: "_blank",
  },
  {
    label: "GitHub",
    icon: <GithubLogo color={theme.colors.primary} size={24} />,
    href: "https://github.com/FernandoRidam",
    target: "_blank",
  },
  {
    label: "Whatsapp",
    icon: <WhatsappLogo color={theme.colors.primary} size={24} />,
    href: "https://wa.me/5562996757320",
    target: "_blank",
  },
  {
    label: "Email",
    icon: <EnvelopeSimple color={theme.colors.primary} size={24} />,
    href: "mailto:fernando@ridam.dev.br",
    target: "_blank",
  },
];

export const Portfolio = () => {
  return (
    <>
      <Section $fullHeight>
        <MarginBox $margin="0 0 40px 0">
          <Logo size={Sizes.lg} />
        </MarginBox>

        <Title>Fernando Ridam</Title>
        <SubTitle>Desenvolvedor Fullstack</SubTitle>

        <ScrollButton href="#about">
          <CaretDoubleDown color={theme.colors.primary} size={32} />
        </ScrollButton>
      </Section>

      <Section id="about" $background={theme.colors.background}>
        <Content>
          <SectionTitle label="Sobre Mim" />

          <MarginBox $margin="32px 0 0 0">
            <About>
              <h4>
                Me chamo <strong>Fernando Ri</strong>beiro <strong>Dam</strong>asceno, tenho {age} anos, moro na cidade de Aparecida de Goiânia - GO, atuo como desenvolvedor fullstack {timeExperience}.
                Com experiência em
                desenvolvimento de aplicativos móveis, aplicações web e sistemas desktop. Almejo crescer
                profissionalmente colaborando com projetos desafiadores e de grande importância. Busco sempre
                me atualizar quanto a tecnologias, métodos e processos a fim de melhorar minhas habilidades.
                Parte do meu conhecimento inicial foi adquirido de forma autodidata, hoje busco me profissionalizar cursando Análise e desenvolvimento de sistemas na <strong>UniGoiás</strong>.
              </h4>

              <Profile size={Sizes.md} />
            </About>

            <DownloadButton href="./CURRICULO - FERNANDO DAMASCENO.pdf" download>
              Currículo

              <Download
                size={16}
                weight="bold"
              />
            </DownloadButton>
          </MarginBox>
        </Content>
      </Section>

      <Section $background={theme.colors.background}>
        <Content>
          <SectionTitle label="Habilidades" />

          <MarginBox $margin="32px 0 0 0">
            <Grid $columns={3} $gap="32px" $width="100%">
              {
                skills.map((skill) => <Skill key={skill.name} {...skill} />)
              }
            </Grid>
          </MarginBox>
        </Content>
      </Section>

      <Section $background={theme.colors.background}>
        <Content>
          <SectionTitle label="Projetos" />

          <MarginBox $margin="32px 0 0 0">
            <Grid $columns={2} $gap="32px" $width="100%">
              {
                projects.map((project) => <Project key={project.name} {...project} />)
              }
            </Grid>
          </MarginBox>
        </Content>
      </Section>

      <Section $background={theme.colors.background}>
        <Content $align="center">
          <SectionTitle label="Links" />

          <MarginBox $margin="32px 0 0 0">
            <Grid $columns={4} $mobileColumns={2} $gap="56px" $width="424px">
              {
                links.map((link) => <Link key={link.label} {...link} />)
              }
            </Grid>
          </MarginBox>
        </Content>
      </Section>

      <Footer>
        <LogoHorizontal size={Sizes.sm} />
      </Footer>
    </>
  );
};
