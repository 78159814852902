import { Outlet } from "react-router-dom";
import { Background, LayoutView } from "./styles";

export const Layout = () => {
  return (
    <LayoutView>
      <Outlet />

      <Background />
    </LayoutView>
  );
};
