import styled from "styled-components";

export const Link = styled.a`
  width: 100%;
  text-decoration: none;
`;

export const Label = styled.span`
  flex: 1;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  letter-spacing: 0.24em;
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;
