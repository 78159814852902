import { LinkProps } from "../../types/interfaces";
import { Button } from "../Button";
import { LinkView } from "./styles";

export const Link: React.FC<LinkProps> = ({
  icon,
  label,
  ...rest
}) => {
  return (
    <LinkView {...rest}>
      <Button>
      {icon}

<span>{label}</span>
      </Button>
    </LinkView>
  );
};
